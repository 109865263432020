import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Spinner, Table } from "react-bootstrap";
import { ModalsContext } from "SGP-contexts/ModalsContext";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import {
  NotaFiscalDeEntradaSchema,
  NotaFiscalDeSaidaSchema,
} from "Schemas/NotaFiscalDeEntradaSchema";
import { placaMask } from "Schemas/Masks";
import { LoginDataContext } from "SGP-contexts/LoginDataContext";

export const NotaFiscalModal = ({ dadosPesagem, tokenPesagemSelecionada }) => {
  const {
    nfModal,
    setNfModal,
    setSucessoCadastro,
    setFalhaCadastro,
    setShowImage,
    setImage,
  } = useContext(ModalsContext);
  const { getUserName } = useContext(LoginDataContext);

  const [urlPdf, setUrlPdf] = useState({
    state: false,
    url: ""
  });

  const [tipoNotaFiscal, setTipoNotaFiscal] = useState("entrada");
  const [loading, setLoading] = useState(false);
  const [loadingBuscarAgendamento, setLoadingBuscarAgendamento] = useState(false);

  const [agendamentos, setAgendamentos] = useState([]);
  const [falhaSelectAgendamento, setFalhaSelectAgendamento] = useState({
    estado: false,
    mensagemDeErro: "",
  });

  const [validationSchema, setValidationSchema] = useState(
    NotaFiscalDeEntradaSchema
  );

  const [agendamentoSelecionado, setAgendamentoSelecionado] = useState({});
  const [viewAgendamentos, setViewAgendamentos] = useState(false);

  const apiUrl = process.env.REACT_APP_APIURL;

  const [registro] = dadosPesagem.filter(
    (item) => item.tokenPesagem === tokenPesagemSelecionada
  );

  const [placaCarretaInformada, setPlacaCarretaInformada] = useState("");

  const [dadosVeiculo, setDadosVeiculo] = useState({});
  const [pesoLiquido, setPesoLiquido] = useState(-3);

  const postNotaFiscal = (values) => {
    axios
      .post(`${apiUrl}/apiwebapp/Pesagem/IncluirNotaFiscalEntrada`, values)
      .then((res) => {
        nfModal.callback().then(() => {
          setSucessoCadastro({
            estado: true,
            mensagem: res.data.mensagem,
          });

          setNfModal({
            ...nfModal,
            estado: !nfModal.estado,
          });
        });
      })
      .catch(() => {
        setNfModal({
          ...nfModal,
          estado: !nfModal.estado,
        });

        setFalhaCadastro({
          estado: true,
          mensagemDeErro: `Erro ao salvar a nota fiscal de entrada para o veículo ${values.placaCarreta}`,
        });
      })
      .finally(() => setLoading(false));
  };

  const marcarParaNotaTbe = (values) => {
    if (agendamentoSelecionado.id === undefined || agendamentoSelecionado.id === null || agendamentoSelecionado.id === '') {
      setFalhaSelectAgendamento({
        estado: true,
        mensagemDeErro: "É necessário selecionar um agendamento para salvar.",
      });
      setLoading(false);
      return;
    }
    values.agendamento = agendamentoSelecionado.id || '';

    //se agendamento for do tipo 1, é um TBE
    if (agendamentoSelecionado.tipo === 1) {
      const userName = getUserName();
      axios
        .post(
          `${apiUrl}/api/TBe/IncluirTBe?TokenPesagem=${values.idPesagem}&agendamentoTBeId=${values.agendamento ? values.agendamento : agendamentoSelecionado.id}&placa=${values.placaCarreta}&operador=${userName}`
        )
        .then((res) => {
          nfModal.callback().then(() => {
            setSucessoCadastro({
              estado: true,
              mensagem: 'Tbe incluído com sucesso.',
            });

            setUrlPdf({
              state: true,
              url: res.data.urlPdf
            });


            setNfModal({
              ...nfModal,
              estado: !nfModal.estado,
            });
            setAgendamentoSelecionado({});
            setFalhaSelectAgendamento({
              estado: false,
              mensagemDeErro: "",
            });
            setLoading(false);
          });
        })
        .catch((error) => {
          setNfModal({
            ...nfModal,
            estado: !nfModal.estado,
          });

          setAgendamentoSelecionado({});
          setUrlPdf({
            state: false,
            url: ''
          });
          setFalhaSelectAgendamento({
            estado: false,
            mensagemDeErro: "",
          });

          setFalhaCadastro({
            estado: true,
            mensagemDeErro: error.response.data,
          });

          setLoading(false);
        });
    }
    //se agendamento for do tipo 2, é uma NFS
    else if (agendamentoSelecionado.tipo === 2) {
      axios.get(`${apiUrl}/NF/GetDadosParaNF/${values.balanca}/Plataforma/${values.plataforma}/Placa/${values.placaCarreta}?TokenPesagem=${values.idPesagem}&agendamentoId=${values.agendamento ? values.agendamento : agendamentoSelecionado.id}`)
        .then((res) => {

          if (res.data.sucesso === true) {
            setSucessoCadastro({
              estado: true,
              mensagem: 'NFs incluída com sucesso.',
            });

            setNfModal({
              ...nfModal,
              estado: !nfModal.estado,
            });
            setAgendamentoSelecionado({});
            setFalhaSelectAgendamento({
              estado: false,
              mensagemDeErro: "",
            });
            setLoading(false);
          }
          else if (res.data.sucesso === false) {
            setFalhaCadastro({
              estado: true,
              mensagemDeErro: res.data.mensagem,
            });
            setNfModal({
              ...nfModal,
              estado: false,
            });

            setAgendamentoSelecionado({});
            setFalhaSelectAgendamento({
              estado: false,
              mensagemDeErro: "",
            });
            setLoading(false);
          }


        })
        .catch((error) => {
          if (error.response) {
            setFalhaCadastro({
              estado: true,
              mensagemDeErro: error.response.data.mensagem,
            });
          }
          setNfModal({
            ...nfModal,
            estado: false,
          });

          setAgendamentoSelecionado({});
          setFalhaSelectAgendamento({
            estado: false,
            mensagemDeErro: "",
          });
          setLoading(false);
        });
    }


  };

  const marcarParaNotaDeSaida = (values) => {
    axios
      .put(
        `${apiUrl}/apiwebapp/Pesagem/IdentificarPesagemCarretaParaNFs?tokenPesagem=${values.idPesagem}&placa=${values.placaCarreta}`
      )
      .then((res) => {
        nfModal.callback().then(() => {
          setSucessoCadastro({
            estado: true,
            mensagem: res.data.mensagem,
          });

          setNfModal({
            ...nfModal,
            estado: !nfModal.estado,
          });
          setLoading(false);
        });
      })
      .catch((error) => {
        console.log(error);
        setNfModal({
          ...nfModal,
          estado: !nfModal.estado,
        });

        setFalhaCadastro({
          estado: true,
          mensagemDeErro: error.response.data.mensagem,
        });

        setLoading(false);
      });
  };

  const getAgentamentos = (placaCarreta) => {
    setPesoLiquido(0);
    setLoadingBuscarAgendamento(true)
    axios
      .get(`${apiUrl}/api/TBe/ListarAgendamentosTBe?placa=${placaCarreta}`)
      .then((res) => {
        if (res.data.sucesso === true) {
          if (res.data.retorno.length === 0) {
            setFalhaSelectAgendamento({
              estado: true,
              mensagemDeErro: "Não foram encontrados agendamentos para a placa informada.",
            });
            setLoadingBuscarAgendamento(false);
          }
          else {
            setAgendamentos(res.data.retorno);
            setViewAgendamentos(true);
            setLoadingBuscarAgendamento(false)
          }
        }
        else {
          setFalhaSelectAgendamento({
            estado: true,
            mensagemDeErro: "Falha ao buscar agendamentos.",
          });
          setLoadingBuscarAgendamento(false);
        }
        setLoadingBuscarAgendamento(false);
      })
      .catch((error) => {
        console.log(error);
        setFalhaSelectAgendamento({
          estado: true,
          mensagemDeErro: "Falha ao buscar agendamentos.",
        });
        setLoadingBuscarAgendamento(false);
      });
  };

  const formatarPeso = (peso) => {
    return (peso / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3 });
  };

  const buscaDadosVeiculo = (placaCarreta) => {
    axios
      .get(`${apiUrl}/api/Carretas/${placaCarreta}`)
      .then((res) => {
        setDadosVeiculo(res.data);
        setPesoLiquido(registro?.peso - res.data.ultimaTara);
        console.log('DADOS PESAGEM', registro);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatarQuantidadeSelecionada = (quantidade) => {
    let quantidadeStr = quantidade.toString().replace('.', ',');
  
    if (!quantidadeStr.includes(',')) {
      quantidadeStr = parseFloat(quantidade).toFixed(3).replace('.', ',');
    } else {
      const partes = quantidadeStr.split(',');
      if (partes[1].length < 3) {
        quantidadeStr = parseFloat(quantidadeStr.replace(',', '.')).toFixed(3).replace('.', ',');
      }
    }
  
    return quantidadeStr;
  };


  useEffect(() => {
    if (tipoNotaFiscal !== "entrada") {
      setTipoNotaFiscal("entrada");
      setValidationSchema(NotaFiscalDeEntradaSchema);
    }
  }, [nfModal]);

  return (
    <>
      {nfModal.estado && (
        <div
          onClick={() => {
            setNfModal({ ...nfModal, estado: !nfModal.estado })
            setAgendamentoSelecionado({});
            setFalhaSelectAgendamento({
              estado: false,
              mensagemDeErro: "",
            });
            setPlacaCarretaInformada("");

          }}
        >
          <Modal show={nfModal} onClick={(event) => event.stopPropagation()}>
            <Modal.Header
              closeButton
              onHide={() => {
                setNfModal({ ...nfModal, estado: !nfModal.estado });
                setAgendamentoSelecionado({});
                setPlacaCarretaInformada("");
              }}
            >
              <Modal.Title>Adicionar documento fiscal </Modal.Title>
            </Modal.Header>

            <Formik
              initialValues={{
                balanca: registro?.balanca,
                plataforma: registro?.plataforma,
                idPesagem: registro?.tokenPesagem,
                placaCarreta:
                  registro?.placaCarreta !== null ? registro?.placaCarreta : "",
                chaveNFEntrada:
                  registro?.chaveNFEntrada !== null
                    ? registro?.chaveNFEntrada
                    : "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setLoading(true);

                if (tipoNotaFiscal === "entrada") {
                  postNotaFiscal(values);
                } else if (tipoNotaFiscal === "tbe") {
                  marcarParaNotaTbe(values);
                } else (
                  marcarParaNotaDeSaida(values)
                )
              }}
            >
              {({ errors, touched, setFieldValue }) => {
                return (
                  <Form>
                    <Modal.Body>
                      <div className="row">
                        <div className="form-group">
                          <label htmlFor="idPesagem" className="ul-form__label">
                            Id da pesagem
                          </label>
                          <Field
                            type="text"
                            className="form-control mb-2"
                            placeholder="Entre com o id da pesagem"
                            name="idPesagem"
                            id="idPesagem"
                            disabled={
                              registro?.tokenPesagem !== null ? true : false
                            }
                            style={{
                              cursor: `${registro?.tokenPesagem !== null
                                ? "not-allowed"
                                : ""
                                }`,
                            }}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col">
                          <label htmlFor="balanca" className="ul-form__label">
                            Balança
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Entre com a balança do veículo"
                            name="balanca"
                            id="balanca"
                            disabled={registro?.balanca !== null ? true : false}
                            style={{
                              cursor: `${registro?.balanca !== null ? "not-allowed" : ""
                                }`,
                            }}
                          />
                        </div>

                        <div className="form-group col">
                          <label
                            htmlFor="plataforma"
                            className="ul-form__label"
                          >
                            Plataforma
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder="Entre com a plataforma da balança"
                            name="plataforma"
                            id="plataforma"
                            disabled={
                              registro?.plataforma !== null ? true : false
                            }
                            style={{
                              cursor: `${registro?.plataforma !== null
                                ? "not-allowed"
                                : ""
                                }`,
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-4 mb-2">
                        <div className="col text-center">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="notaDeEntrada"
                              name="tipoNotaFiscal"
                              defaultChecked
                              onChange={() => {
                                setTipoNotaFiscal("entrada");
                                setValidationSchema(NotaFiscalDeEntradaSchema);
                              }}
                            />
                            <label
                              className="form-check-label fw-bold"
                              htmlFor="notaDeEntrada"
                            >
                              Nota fiscal de entrada
                            </label>
                          </div>

                          <div className="form-check form-check-inline row-reverse">
                            <input
                              className="form-check-input"
                              type="radio"
                              id="notaTbe"
                              name="tipoNotaFiscal"
                              onChange={() => {
                                setTipoNotaFiscal("tbe");
                                setValidationSchema(NotaFiscalDeSaidaSchema);
                              }}
                            />
                            <label
                              className="form-check-label fw-bold"
                              htmlFor="notaTbe"
                            >
                              Documento Fiscal de Saída
                            </label>
                          </div>
                        </div>
                      </div>

                      {tipoNotaFiscal === "entrada" ? (
                        <>
                          <div className="row">
                            <div className="form-group">
                              <label
                                htmlFor="placaCarreta"
                                className="ul-form__label"
                              >
                                Placa do veículo
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Entre com a placa da carreta"
                                name="placaCarreta"
                                id="placaCarreta"
                                onChange={(event) => {
                                  const value = event.currentTarget.value || "";
                                  setFieldValue(
                                    "placaCarreta",
                                    placaMask(value.toUpperCase())
                                  );
                                }}
                              />
                              {errors.placaCarreta && touched.placaCarreta ? (
                                <small className="d-flex w-100 text-danger py-1">
                                  *{errors.placaCarreta}
                                </small>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="form-group">
                              <label
                                className="ul-form__label"
                                htmlFor="chaveNFEntrada"
                              >
                                Chave da nota fiscal de entrada
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Entre com a chave da nota fiscal"
                                name="chaveNFEntrada"
                                id="chaveNFEntrada"
                              />
                              {errors.chaveNFEntrada &&
                                touched.chaveNFEntrada ? (
                                <small className="d-flex w-100 text-danger py-1">
                                  *{errors.chaveNFEntrada}
                                </small>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : tipoNotaFiscal === "tbe" ? (
                        <>
                          <div className="row">
                            <div className="form-group">
                              <label
                                htmlFor="placaCarreta"
                                className="ul-form__label"
                              >
                                Placa do veículo
                              </label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Entre com a placa da carreta"
                                name="placaCarreta"
                                id="placaCarreta"
                                onChange={(event) => {
                                  const value = event.currentTarget.value || "";
                                  setFieldValue(
                                    "placaCarreta",
                                    placaMask(value.toUpperCase())
                                  );
                                  setPlacaCarretaInformada(value.toUpperCase());
                                }}
                              />
                              {errors.placaCarreta && touched.placaCarreta ? (
                                <small className="d-flex w-100 text-danger py-1">
                                  *{errors.placaCarreta}
                                </small>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className="form-group d-flex justify-content-center">
                              <Button
                                style={{ color: "white" }}
                                variant="primary"
                                disabled={errors.placaCarreta || !touched.placaCarreta}
                                onClick={() => {
                                  getAgentamentos(placaCarretaInformada);
                                  buscaDadosVeiculo(placaCarretaInformada);
                                }}
                              >
                                {Object.keys(agendamentoSelecionado).length === 0 && !loadingBuscarAgendamento ? ("Buscar Agendamentos")
                                  : Object.keys(agendamentoSelecionado).length !== 0 && !loadingBuscarAgendamento ? ("Alterar Agendamento") : null}

                                {loadingBuscarAgendamento ? (
                                  <Spinner
                                    animation="border"
                                    variant="light"
                                    size="sm"
                                  />
                                ) : null}
                              </Button>
                            </div>
                          </div>
                          {falhaSelectAgendamento.estado ? (
                            <div className="row">
                              <div className="form-group d-flex justify-content-center">
                                <small className="d-flex w-100 text-danger py-1 justify-content-center">
                                  {falhaSelectAgendamento.mensagemDeErro}
                                </small>
                              </div>
                            </div>
                          ) : null}

                          {Object.keys(agendamentoSelecionado).length !== 0 ? (
                            <>
                              <div className="row">
                                <div className="form-group">
                                  <label
                                    htmlFor="AgendamentoSelecionadoTitle"
                                    className="ul-form__label"
                                    style={{ fontWeight: "bold", marginTop: "10px" }}
                                  >
                                    Dados da pesagem:
                                  </label>
                                  <div className="row">
                                    <div className="form-group col">
                                      <label htmlFor="pesoBrutoPesagem" className="ul-form__label">
                                        Peso bruto (t):
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="pesoBrutoPesagem"
                                        id="pesoBrutoPesagem"
                                        value={formatarPeso(registro?.peso)}
                                        readOnly
                                      />
                                    </div>
                                    <div className="form-group col">
                                      <label htmlFor="modeloVeiculo" className="ul-form__label">
                                        Modelo do veículo
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        placeholder="Modelo do veículo"
                                        name="modeloVeiculo"
                                        id="modeloVeiculo"
                                        value={dadosVeiculo?.modeloVeiculo}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col">
                                      <label htmlFor="taraVeiculoSelecionado" className="ul-form__label">
                                        Tara do veículo selecionado (t):
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="taraVeiculoSelecionado"
                                        id="taraVeiculoSelecionado"
                                        value={formatarPeso(dadosVeiculo?.ultimaTara)}
                                        readOnly
                                      />
                                    </div>
                                    <div className="form-group col">
                                      <label htmlFor="pesoLiquidoPesagem" className="ul-form__label">
                                        Peso líquido (t):
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="pesoLiquidoPesagem"
                                        id="pesoLiquidoPesagem"
                                        value={formatarPeso(pesoLiquido)}
                                        readOnly
                                      />
                                      {pesoLiquido < 0 && (
                                        <div style={{ color: 'red', marginTop: '3px' }}>
                                          <span style={
                                            { fontSize: '10px' }
                                          }>O peso líquido não pode ser negativo.</span>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col">
                                      <label htmlFor="quantidadeEstimada" className="ul-form__label">
                                        Quantidade estimada (t):
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="quantidadeEstimada"
                                        id="quantidadeEstimada"
                                        value={formatarQuantidadeSelecionada(agendamentoSelecionado?.fluig?.quantidadeEstimada)}
                                        readOnly
                                      />
                                    </div>

                                    <div className="form-group col">

                                    </div>

                                  </div>
                                </div>
                              </div>


                              <div className="row">
                                <div className="form-group">
                                  <label
                                    htmlFor="AgendamentoSelecionadoTitle"
                                    className="ul-form__label"
                                    style={{ fontWeight: "bold", marginTop: "10px" }}
                                  >
                                    Agendamento selecionado:
                                  </label>
                                  <div className="row">
                                    <div className="form-group col">
                                      <label htmlFor="agendamentoTransportadora" className="ul-form__label">
                                        Transportadora
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="agendamentoTransportador"
                                        id="agendamentoTransportador"
                                        value={agendamentoSelecionado?.transportadora?.razaoSocial}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col">
                                      <label
                                        htmlFor="agendamentoProtocolo"
                                        className="ul-form__label"
                                      >
                                        Protocolo
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="agendamentoProtocol"
                                        id="agendamentoProtocol"
                                        value={agendamentoSelecionado?.fluig?.protocolo}
                                        readOnly
                                      />
                                    </div>

                                    <div className="form-group col">
                                      <label
                                        htmlFor="agendamentoTipo"
                                        className="ul-form__label"
                                      >
                                        Tipo
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="agendamentoTip"
                                        id="agendamentoTip"
                                        value={agendamentoSelecionado?.tipo === 1 ? "TBe" : agendamentoSelecionado?.tipo === 2 ? "NFS" : ""}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <div className="row">
                          <div className="form-group">
                            <label
                              htmlFor="placaCarreta"
                              className="ul-form__label"
                            >
                              Placa da carreta
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              placeholder="Entre com a placa da carreta"
                              name="placaCarreta"
                              id="placaCarreta"
                              onChange={(event) => {
                                const value = event.currentTarget.value || "";
                                setFieldValue(
                                  "placaCarreta",
                                  placaMask(value.toUpperCase())
                                );
                              }}
                            />
                            {errors.placaCarreta && touched.placaCarreta ? (
                              <small className="d-flex w-100 text-danger py-1">
                                *{errors.placaCarreta}
                              </small>
                            ) : null}
                          </div>
                        </div>
                      )}

                      <section>
                        <br />
                        <Modal.Title>Fotos</Modal.Title>
                        <div className="custom-separator"></div>
                        <div className="row text-center ">
                          {registro?.urlImagens !== null &&
                            registro?.urlImagens.map((info, index) => (
                              <div key={index}>
                                <div className="col">
                                  <img
                                    src={info}
                                    alt="Imagem do caminhão"
                                    className="w-75 m-2 rounded cursor-pointer"
                                    onClick={() => {
                                      setShowImage(true);
                                      setImage(info);
                                    }}
                                  />
                                </div>
                                <div className="w-100"></div>
                              </div>
                            ))}
                        </div>
                      </section>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        type="submit"
                        variant="outline-success"
                        disabled={loading ? true :
                          pesoLiquido < 0 ? true : false
                        }
                      >
                        {loading ? (
                          <Spinner
                            animation="border"
                            variant="success"
                            size="sm"
                          />
                        ) : (
                          `Salvar`
                        )}
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() => {
                          setAgendamentoSelecionado({});
                          setNfModal({ ...nfModal, estado: !nfModal.estado })
                          setFalhaSelectAgendamento({
                            estado: false,
                            mensagemDeErro: "",
                          });
                          setPlacaCarretaInformada("");
                          setDadosVeiculo({});
                          setPesoLiquido(0);
                        }}
                        disabled={loading ? true : false}
                      >
                        Cancelar
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
          </Modal>
        </div>
      )}


      <Modal show={viewAgendamentos} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Selecionar Agendamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <Table>
              <thead>
                <tr>
                  <th>Transportadora</th>
                  <th>Protocolo</th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody>
                {agendamentos.map((agendamento, index) => (
                  <tr
                    key={index}
                    onClick={() => {
                      setAgendamentoSelecionado(agendamento);
                      setFalhaSelectAgendamento({
                        estado: false,
                        mensagemDeErro: "",
                      });
                      setViewAgendamentos(false);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{agendamento.transportadora.razaoSocial}</td>
                    <td>{agendamento.fluig.protocolo}</td>
                    {agendamento.tipo === 1 ? <td>{"TBE"}</td> : agendamento.tipo === 2 ? <td>{"NFS"}</td> : <td></td>}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ color: "white" }}
            variant="danger"
            onClick={() => {
              if (Object.keys(agendamentoSelecionado).length === 0) {
                setAgendamentoSelecionado({});
              }
              setViewAgendamentos(false);
            }}
          >
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={urlPdf.state} size="xl">
        <Modal.Header>
          <Modal.Title>Pdf TB-e</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row text-center">
            {urlPdf.state === true && urlPdf.url !== '' &&
              <>
                <div className="col" style={{ minHeight: "80vh" }}>
                  <iframe
                    src={urlPdf.url}
                    style={{ width: '100%', height: "100%", border: 'none' }}
                    title="PDF do TB-e"
                  ></iframe>
                </div>
              </>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ color: "white" }}
            variant="danger"
            onClick={() => {
              setUrlPdf({
                state: false,
                url: ""
              });
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};
