import React from "react";
import { Button, Spinner } from "react-bootstrap";

const ButtonWithLoading = ({
  loading,
  disabled,
  form = undefined,
  buttonVariant = "outline-info",
  buttonType = "submit",
  spinnerVariant = "info",
  children,
  title = "",
}) => {
  return (
    <Button
      type={String(buttonType)}
      variant={String(buttonVariant)}
      disabled={loading || disabled}
      form={form}
      title={title}
    >
      {loading ? (
        <Spinner
          animation="border"
          variant={String(spinnerVariant)}
          size="sm"
        />
      ) : (
        <span>{children}</span>
      )}
    </Button>
  );
};

export default ButtonWithLoading;
